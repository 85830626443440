import React, { useState, useEffect } from 'react';
import axios from 'axios';
import yaml from 'js-yaml';
import { FaMicrophone, FaTimes } from 'react-icons/fa';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import RecordRTC from 'recordrtc';

function App() {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [textInput, setTextInput] = useState("");
    const [analyzing, setAnalyzing] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [isTokenValid, setIsTokenValid] = useState(true);
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [hideSentiments, setHideSentiments] = useState(false);

    useEffect(() => {
        fetchFiles();
    }, []);

    const generateAreaColors = (areas) => {
        const colors = ["#FFDDC1", "#FFE4E1", "#FFFACD", "#E0FFFF", "#D8BFD8", "#E6E6FA", "#FFF0F5", "#F0FFF0", "#F5F5DC", "#FAF0E6"];
        const areaColors = {};
        let colorIndex = 0;

        areas.forEach(area => {
            if (!areaColors[area]) {
                areaColors[area] = colors[colorIndex % colors.length];
                colorIndex++;
            }
        });

        return areaColors;
    };

    const sortAreasAndThemes = (themes) => {
        const themesByArea = themes.reduce((acc, theme) => {
            const area = theme.generic_area.split('/')[0];
            if (!acc[area]) {
                acc[area] = [];
            }
            acc[area].push(theme);
            return acc;
        }, {});

        const sortedAreas = Object.keys(themesByArea).sort((a, b) => a.localeCompare(b));

        sortedAreas.forEach(area => {
            themesByArea[area].sort((a, b) => a.theme.localeCompare(b.theme));
        });

        return sortedAreas.flatMap(area => themesByArea[area]);
    };

    const highlightPortions = (text, insightsYaml) => {
        let insights = [];
        try {
            insights = yaml.load(insightsYaml);
        } catch (e) {
            console.error(e);
        }

        const themes = insights.reviews ? insights.reviews.map(review => review.themes).flat() : [];
        const sortedThemes = sortAreasAndThemes(themes);
        let highlightedText = text;

        sortedThemes.forEach(theme => {
            theme.portions.forEach(portion => {
                const portionRegex = portion.portion.split('').map(char => {
                    return char.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                }).join('\\s*');
                
                const regex = new RegExp(`(${portionRegex})`, 'gs');
                if (!hideSentiments) {
                    if (portion.sentiment === 'Positive') {
                        highlightedText = highlightedText.replace(regex, '<span style="border-bottom: 3px solid green;">$1</span>');
                    } else if (portion.sentiment === 'Negative') {
                        highlightedText = highlightedText.replace(regex, '<span style="border-bottom: 3px solid red;">$1</span>');
                    }
                }
            });
        });

        return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
    };

    const fetchFiles = async () => {
        const token = new URLSearchParams(window.location.search).get('token');
        if (!token) {
            setAlertMessage("Token is missing. Please contact tommaso.castelli@i2d.it to use InsightGPT");
            setShowAlert(true);
            setIsTokenValid(false);
            return;
        }

        try {
            const response = await axios.get(`/api/files?token=${token}`);
            const filesWithDisplayNames = response.data.map(file => {
                let displayFilename = '';
                if (file.type === 'text') {
                    displayFilename = 'Text Review';
                } else {
                    displayFilename = 'Recording';
                }
                return {...file, displayFilename};
            });
            setFiles(filesWithDisplayNames);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setAlertMessage('You have reached the maximum number of requests for this trial. Please contact tommaso.castelli@i2d.it to continue using InsightGPT');
                setShowAlert(true);
                setIsTokenValid(false);
            } else {
                console.error('Error fetching files:', error);
            }
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);

            const token = new URLSearchParams(window.location.search).get('token');
            if (!token) {
                setAlertMessage('Token is missing');
                setShowAlert(true);
                setIsTokenValid(false);
                setUploading(false);
                return;
            }

            try {
                await axios.post(`/api/upload?token=${token}&type=recording`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                fetchFiles();
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setAlertMessage("You have reached the maximum number of requests for this demo. Contact tommaso.castelli@i2d.it to continue using InsightGPT.");
                    setShowAlert(true);
                } else if (error.response && error.response.status === 401) {
                    setAlertMessage("Your token was not recognized. Contact tommaso.castelli@i2d.it to use InsightGPT.");
                    setShowAlert(true);
                    setIsTokenValid(false);
                } else {
                    console.error('Error uploading file:', error.response.data);
                }
            } finally {
                setSelectedFile(null);
                setUploading(false);
            }
        }
    };

    const handleAnalyzeText = async () => {
        if (!textInput) {
            setAlertMessage("Text is missing");
            setShowAlert(true);
            return;
        }

        setAnalyzing(true);
        const token = new URLSearchParams(window.location.search).get('token');
        if (!token) {
            setAlertMessage('Token is missing');
            setShowAlert(true);
            setIsTokenValid(false);
            setAnalyzing(false);
            return;
        }

        try {
            await axios.post(`/api/analyze?token=${token}`, {text: textInput});
            fetchFiles();
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setAlertMessage("You have reached the maximum number of requests for this trial. Contact tommaso.castelli@i2d.it to continue using InsightGPT.");
                setShowAlert(true);
            } else if (error.response && error.response.status === 401) {
                setAlertMessage("Your token was not recognized. Contact tommaso.castelli@i2d.it to use InsightGPT.");
                setShowAlert(true);
                setIsTokenValid(false);
            } else {
                console.error('Error analyzing text:', error.response.data);
            }
        } finally {
            setAnalyzing(false);
        }
    };

    const handleFileDelete = async (fileId) => {
        const token = new URLSearchParams(window.location.search).get('token');
        if (!token) {
            setAlertMessage('Token is missing');
            setShowAlert(true);
            setIsTokenValid(false);
            return;
        }

        await axios.delete(`/api/delete/${fileId}?token=${token}`);
        fetchFiles();
    };

    const handleRecord = async () => {
        if (isRecording) {
            recorder.stopRecording(async () => {
                const blob = recorder.getBlob();
                const formData = new FormData();
                formData.append('file', blob, `recording_${Date.now()}.mp3`);

                const token = new URLSearchParams(window.location.search).get('token');
                if (!token) {
                    setAlertMessage('Token is missing');
                    setShowAlert(true);
                    setIsTokenValid(false);
                    return;
                }

                setUploading(true);
                try {
                    await axios.post(`/api/upload?token=${token}&type=recording`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    fetchFiles();
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        setAlertMessage("You have reached the maximum number of requests for this trial. Contact tommaso.castelli@i2d.it to continue using InsightGPT.");
                        setShowAlert(true);
                    } else if (error.response && error.response.status === 401) {
                        setAlertMessage("Your token was not recognized. Contact tommaso.castelli@i2d.it to use InsightGPT.");
                        setShowAlert(true);
                        setIsTokenValid(false);
                    } else {
                        console.error('Error uploading file:', error.response.data);
                    }
                } finally {
                    setUploading(false);
                }
                setIsRecording(false);
            });
        } else {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const newRecorder = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/mp3'
            });
            setRecorder(newRecorder);
            newRecorder.startRecording();
            setIsRecording(true);
        }
    };

    const renderTags = (tags, sentiment) => {
        let color;

        switch (sentiment) {
            case 'Positive':
                color = 'lightgreen';
                break;
            case 'Negative':
                color = 'lightcoral';
                break;
            case 'Neutral':
                color = 'lightgray';
                break;
            default:
                color = 'transparent';
        }

        if (tags === null) {
            return <span></span>;
        }

        return tags.map((tag, index) => (
            <span
                key={index}
                className="tag"
                style={{ backgroundColor: color }}
            >
                {tag}
            </span>
        ));
    };

    const toggleAreaHighlight = (area) => {
        if (selectedAreas.includes(area)) {
            setSelectedAreas(selectedAreas.filter(selectedArea => selectedArea !== area));
        } else {
            setSelectedAreas([...selectedAreas, area]);
        }
    };

    const getHighlightedText = (text, insightsYaml) => {
        let insights = [];
        try {
            insights = yaml.load(insightsYaml);
        } catch (e) {
            console.error(e);
        }

        const themes = insights.reviews ? insights.reviews.map(review => review.themes).flat() : [];
        const sortedThemes = sortAreasAndThemes(themes);
        let highlightedText = text;

        sortedThemes.forEach(theme => {
            theme.portions.forEach(portion => {
                const portionRegex = portion.portion.split('').map(char => {
                    return char.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                }).join('\\s*');
                
                const regex = new RegExp(`(${portionRegex})`, 'gs');
                const areaColor = selectedAreas.includes(theme.generic_area.split('/')[0]) ? areaColors[theme.generic_area.split('/')[0]] : 'transparent';
                highlightedText = highlightedText.replace(regex, `<span style="background-color: ${areaColor}; border-bottom: ${hideSentiments ? 'none' : portion.sentiment === 'Positive' ? '3px solid green' : '3px solid red'};">$1</span>`);
            });
        });

        return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
    };

    const getUniqueAreas = (insightsYaml) => {
        let insights = [];
        try {
            insights = yaml.load(insightsYaml);
        } catch (e) {
            console.error(e);
        }

        const themes = insights.reviews ? insights.reviews.map(review => review.themes).flat() : [];
        const uniqueAreas = [...new Set(themes.map(theme => theme.generic_area.split('/')[0]))];
        return uniqueAreas;
    };

    const clearAllSelections = () => {
        setSelectedAreas([]);
    };

    const toggleHideSentiments = () => {
        setHideSentiments(!hideSentiments);
    };

    const uniqueAreas = [...new Set(files.flatMap(file => getUniqueAreas(file.insights)))];
    const areaColors = generateAreaColors(uniqueAreas);

    return (
        <div className="container">
            {showAlert && (
                <div className="alert alert-warning" role="alert">
                    {alertMessage}
                </div>
            )}
            <div className="header">
                <img className="responsive-logo" src="https://app.insightgpt.it/assets/logo-97f13a22.png" alt="Logo" />
                <br />
                <br />
                <div className="textarea-button-container">
                    <button className={`custom-record-button ${isRecording ? 'recording' : ''}`} onClick={handleRecord}
                        disabled={uploading || analyzing}>
                        {isRecording ? 'Stop Recording' : 'Start Recording'} <FaMicrophone />
                    </button>
                    <textarea
                        className="text-input"
                        placeholder="Write your review"
                        value={textInput}
                        onChange={(e) => setTextInput(e.target.value)}
                        disabled={!isTokenValid}
                    />
                </div>
                <button
                    className="analyze-button"
                    onClick={handleAnalyzeText}
                    disabled={!isTokenValid || !textInput}
                >
                    Analyze
                </button>
                {(uploading || analyzing) && (
                    <div className="progress-bar">
                        <div className="progress"></div>
                    </div>
                )}
            </div>
            <ul className="file-list">
                {files.map(file => (
                    <li key={file.id} className="file-item">
                        <button onClick={() => handleFileDelete(file.id)} className="delete-button">&times;</button>
                        <div className="file-info">
                            <h5 className="filename">{file.displayFilename}</h5>
                            <small className="date">{new Date(file.created_at).toLocaleString()}</small>
                            <div className="text">{getHighlightedText(file.text, file.insights)}</div>
                            <button
                                className="hide-sentiments"
                                onClick={toggleHideSentiments}
                                style={{
                                    backgroundColor: '#ddd',
                                    borderRadius: '15px',
                                    margin: '5px',
                                    padding: '5px 10px',
                                    cursor: 'pointer',
                                    border: '1px solid gray',
                                    width: '150px'
                                }}
                            >
                                {hideSentiments ? 'Show Sentiments' : 'Hide Sentiments'}
                            </button>
                            <div className="area-tags">
                                {getUniqueAreas(file.insights).map(area => (
                                    <button
                                        key={area}
                                        className={`area-tag ${selectedAreas.includes(area) ? 'selected' : ''}`}
                                        style={{
                                            backgroundColor: areaColors[area],
                                            borderRadius: '15px',
                                            margin: '5px',
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                            transform: selectedAreas.includes(area) ? 'translateY(-5px)' : 'translateY(0)',
                                            transition: 'transform 0.2s',
                                            border: '1px solid gray'
                                        }}
                                        onClick={() => toggleAreaHighlight(area)}
                                    >
                                        {area}
                                    </button>
                                ))}
                                {selectedAreas.length > 0 && (
                                    <button
                                        className="clear-selection"
                                        onClick={clearAllSelections}
                                        style={{
                                            backgroundColor: '#ddd',
                                            borderRadius: '15px',
                                            margin: '5px',
                                            padding: '5px 10px',
                                            cursor: 'pointer',
                                            border: '1px solid gray'
                                        }}
                                    >
                                        Clear All <FaTimes />
                                    </button>
                                )}
                            </div>
                            <div className="insights">
                                {yaml.load(file.insights)?.reviews?.map((review, index) => (
                                    <div key={index} className="review">
                                        {sortAreasAndThemes(review.themes).map((theme, themeIndex) => (
                                            <div key={themeIndex} className="theme" style={{ backgroundColor: areaColors[theme.generic_area.split('/')[0]], borderColor: areaColors[theme.generic_area.split('/')[0]], borderWidth: '2px', borderStyle: 'solid' }}>
                                                <div className="theme-header">
                                                    <h6>{theme.theme}</h6>
                                                    <span className="theme-area">{theme.generic_area}</span>
                                                </div>
                                                <div className="theme-portions">
                                                    {theme.portions.map((portion, portionIndex) => (
                                                        <div key={portionIndex} className="portion">
                                                            <p>{portion.portion}</p>
                                                            <div className="tags">
                                                                {renderTags(portion.positive_tags, 'Positive')}
                                                                {renderTags(portion.negative_tags, 'Negative')}
                                                                {renderTags(portion.neutral_tags, 'Neutral')}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                        {file.type !== 'text' && (
                            <audio controls src={`/audio_files/${file.token}/${file.filename}`}
                                className="audio-player"></audio>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default App;
